/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { GMCContext } from "../../GMCContext";
import Footer from "../Comman/Footer";

function Stake() {
  const { link_ref_address } = useParams();

  const {
    fdpkg,
    rdpkg,
    account,
    toastSuccess,
    library,
    dbuser,
    tokenrate,
    formatAddress,
    toastError,
    getudata,
    settings,
    esigner,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(GMCContext);
  const [showRd, setShowRd] = useState(true);
  const [tnc, settnc] = useState(false);
  const [spkg, setspkg] = useState(rdpkg !== null ? rdpkg[0] : null);
  const [spkgid, setspkgid] = useState(0);

  const [amount, setamount] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  function isDivisibleBy10(number) {
    return number % 10 === 0;
  }
  var navigate = useNavigate();

  const onDeposit = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (showRd && dbuser !== null && dbuser.active_r_d) {
      toastError("Your one RD is running");
      settrdone(false);
      return;
    }
    if (!showRd && dbuser !== null && dbuser.active_f_d) {
      toastError("Your one FD is running");
      settrdone(false);
      return;
    }
    if (isDivisibleBy10(amount) === false) {
      toastError("Amount must be divisable by 10");
      settrdone(false);
      return;
    }
    if (!tnc) {
      toastError("Accept Terms & Conditions");
      settrdone(false);
      return;
    }
    if (Number(amount) < (showRd ? 10 : 100)) {
      toastError(`Min ${showRd ? 10 : 100} required`);
      settrdone(false);
      return;
    }
    // if (Number(balance) < Number(total_tokens)) {
    //   toastError("Insufficient balance");
    //   return;
    // }
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }
    var data = await getudata(referral);
    // console.log("Data", data.data.data.active_rank);
    if (data.data.error) {
      toastError("Invalid Referral Address");
      settrdone(false);
      return "";
    }
    if (data.data.data.active_rank === true || data.data.data.agency === true) {
    } else {
      toastError("Referral Address not eligible");
      settrdone(false);
      return "";
    }
    // if (dbuser !== null && dbuser.last_staking > amount) {
    //     toastError(`Stake ${dbuser.last_staking} or more`);
    //     settrdone(false);
    //     return;
    // }
    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    let token_contract = new library.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );

    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
      //   );
      try {
        var stk = await contract.buypackage(
          account,
          referral,
          spkgid,
          ethers.utils.parseUnits(`${amount}`, 8),
          showRd ? 2 : 1,
          ethers.utils.parseUnits(`${amount}`, 8)
        );
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            hash: receipt.transactionHash,
            amount: amount,
            token: total_tokens,
            rate: tokenrate,
            isrd: showRd,
            pkg_id: spkg._id,
          })
          .then((res) => {
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              return "";
            }
          });
        getuserDB();
        settrdone(false);
        navigate("/dashboard");
      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }
    // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
    if (Number(ethers.utils.parseUnits(`${allowance}`, 8)) < total_tokens) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          ethers.utils.parseUnits("100000000", 8)
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_address !== null) {
        // console.log("ref_addressref_addressref_address",dbuser.ref_address);
        setreferral(dbuser.ref_address);
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [link_ref_address, dbuser, account]);
  useEffect(() => {
    settotal_tokens(amount / tokenrate);
  }, [amount, tokenrate]);
  function getDateAfterDays(days) {
    let today = new Date();

    let futureDate = new Date(today);
    futureDate.setDate(today.getDate() + days);

    let day = String(futureDate.getDate()).padStart(2, "0");
    let month = String(futureDate.getMonth() + 1).padStart(2, "0");
    let year = futureDate.getFullYear();

    return `${day}-${month}-${year}`;
  }
  useEffect(() => {
    setspkg(showRd ? rdpkg[spkgid] : fdpkg[spkgid]);
  }, [showRd]);

  console.log("showRd :", showRd);
  console.log("rdpkg :", rdpkg);
  console.log("fdpkg :", fdpkg);
  console.log("spkgid :", spkgid);
  console.log("spkg :", spkg);

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-4">
                <div className="authincation-content rounded-5">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form ">
                        <h2 className="text-center fs-1 mb-4">Smart Defi</h2>

                        <ul className="d-flex justify-content-center nav nav-pills mb-4 ">
                          <li className=" nav-item">
                            <button
                              className={
                                showRd ? "active nav-link " : "nav-link "
                              }
                              onClick={() => setShowRd(!showRd)}
                            >
                              Regular Deposit
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={
                                !showRd ? "active nav-link " : "nav-link "
                              }
                              onClick={() => setShowRd(!showRd)}
                            >
                              Fixed Deposit
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <form>
                            <div className="form-group ">
                              <label className="form-label text-white">
                                Select Goal*
                              </label>
                              <div className="bg-gray rounded-2 ">
                                {/* <ul className="d-flex justify-content-around nav nav-pills p-2  ">
                                  <li className=" nav-item">
                                    <a
                                      href="#navpills-1"
                                      className="nav-link active"
                                      data-bs-toggle="tab"
                                      aria-expanded="false"
                                    >
                                      1 years
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      href="#navpills-2"
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      aria-expanded="false"
                                    >
                                      2 years
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      href="#navpills-3"
                                      className="nav-link"
                                      data-bs-toggle="tab"
                                      aria-expanded="true"
                                    >
                                      3 years
                                    </a>
                                  </li>
                                </ul> */}
                                {showRd ? (
                                  <div>
                                    {rdpkg.map((item, i) => {
                                      return (
                                        <ul className="d-flex justify-content-around nav nav-pills p-2  ">
                                          <li className=" nav-item">
                                            <a
                                              href="#navpills-1"
                                              className="nav-link active"
                                              defaultChecked={i === 0}
                                              onChange={() => {
                                                setspkg(item);
                                                setspkgid(i);
                                              }}
                                            >
                                              <span className="name">
                                                {item.days / 360} Years
                                              </span>
                                            </a>
                                          </li>
                                        </ul>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div>
                                    {rdpkg.map((item, i) => {
                                      return (
                                        <ul className="d-flex justify-content-around nav nav-pills p-2  ">
                                          <li className=" nav-item">
                                            <a
                                              href="#navpills-1"
                                              className="nav-link active"
                                              defaultChecked={i === 0}
                                              onChange={() => setspkg(item)}
                                            >
                                              <span className="name">
                                                {item.days / 360} Years
                                              </span>
                                            </a>
                                          </li>
                                        </ul>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-label text-white">
                                Referral Address
                              </label>
                              {dbuser !== null ? (
                                dbuser.ref_address !== null ? (
                                  <input
                                    type="text"
                                    className="form-control input-rounded bg-black"
                                    value={referral}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control input-rounded bg-black"
                                    placeholder="Enter Referral Address"
                                    value={referral}
                                    onChange={(e) => {
                                      setreferral(e.target.value);
                                    }}
                                  />
                                )
                              ) : (
                                <input
                                  type="text"
                                  className="form-control input-rounded bg-black"
                                  placeholder="Enter Referral Address"
                                  value={referral}
                                  onChange={(e) => {
                                    setreferral(e.target.value);
                                  }}
                                />
                              )}
                            </div>
                            <label className="form-label text-white">
                              Enter Amount {showRd ? "($/month)" : ""}
                            </label>
                            <div className="mb-3 position-relative">
                              <input
                                type="text"
                                className="form-control input-rounded"
                                placeholder="0"
                                onChange={(e) => setamount(e.target.value)}
                              />
                            </div>
                            <div className="d-flex justify-content-between text-white mb-4">
                              <span>Token</span>
                              <span>
                                {total_tokens} {process.env.REACT_APP_TICKER} (${" "}
                                {tokenrate} )
                              </span>
                            </div>
                            {showRd ? (
                              <div className="d-flex justify-content-between text-white mb-4">
                                <span>Premiums</span>
                                <span>
                                  ${" "}
                                  {spkg !== null
                                    ? ` ${spkg?.installments} Months`
                                    : "0"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {showRd ? (
                              <div className="d-flex justify-content-between text-white mb-4">
                                <span>Reward Premiums</span>
                                <span>
                                  $
                                  {/* {spkg !== null ? ` ${spkg.reward} Months` : ""} */}
                                </span>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between text-white mb-4">
                                <span>Reward Premiums</span>
                                <span>
                                  $
                                  {/* {spkg !== null ? ` ${spkg.reward} Months` : ""} */}
                                </span>
                              </div>
                            )}
                            <div className="d-flex justify-content-between text-white mb-4">
                              <span>Maturity Amount</span>
                              {showRd ? (
                                <span>
                                  $ {/* $ {amount * spkg.maturity} */}
                                </span>
                              ) : (
                                <span>
                                  $
                                  {/* $  {Number(amount) +
                                            Number(
                                              (amount * spkg.reward) / 100
                                            )} */}
                                </span>
                              )}
                            </div>
                            <div className="d-flex justify-content-between text-white mb-4">
                              <span>Maturity Date</span>
                              <span>
                                - {/* {getDateAfterDays(spkg.maturity_time)} */}
                              </span>
                            </div>
                            {hash ? (
                              <div className="d-flex justify-content-between text-white mb-4">
                                <span>Tnx hash</span>
                                <span>
                                  {formatAddress(hash)}{" "}
                                  <i className="fa fa-copy"></i>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="d-flex justify-content-center form-check custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheckBox1"
                                onChange={(event) =>
                                  settnc(event.target.checked)
                                }
                              />
                              <label
                                className="form-check-label text-white"
                                htmlFor="customCheckBox1"
                              >
                                <Link to="/terms-and-service">
                                  {" "}
                                  Terms & Conditions
                                </Link>
                              </label>
                            </div>
                            {trdone ? (
                              <div className="text-center">
                                <img
                                  className=" bd-gradient-btn rounded-pill w-10"
                                  src="/assets/imgs/loader.gif"
                                  alt="loading"
                                  style={{ width: "100px" }}
                                />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  className="d-flex align-items-center btn btn-primary w-25"
                                  onClick={() => onDeposit()}
                                >
                                  Invest
                                </button>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Stake;
