import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { GMCContext } from "../../GMCContext";
import { Link } from "react-router-dom";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";
import Web3 from "web3";
import useEthersSigner from '../Sidbar/useEthersSigner'; // Import the hook for getting signer
import { Contract, ethers } from "ethers"; // Import ethers
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useConnect, useDisconnect } from 'wagmi';

export default function PendingMembers() {
  const { formatAddress, settings, web3, contract, formatDateTime, toastSuccess, toastError } =
    useContext(GMCContext)
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [account, setAccount] = useState(null);

  const [web3c, setWeb3c] = useState(null);
  const [value, setValue] = useState(0);
  const [trdone, settrdone] = useState(true);
  const [selected_address, setselected_address] = useState([]);
  const [selected_address_referral, setselected_address_referral] = useState([]);
  const [selected_pacakge, setselected_pacakge] = useState([]);
  const [selected_pacakge_as, setselected_pacakge_as] = useState([]);
  const [selected_address_purchase, setselected_address_purchase] = useState([]);


  const signer = useEthersSigner(); // Call the hook here

  const { isConnected, address } = useAccount();
  const { JsonRpcProvider } = ethers.providers; // Import JsonRpcProvider from the providers namespace
  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "admin",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
          search: search,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };

  const selectAll = (event) => {
    if (event?.target?.checked) {
      // Reset arrays and total amount to start fresh
      let allSelectedAddresses = [];
      let allSelectedRefAddresses = [];
      let allSelectedPurch = [];
      let allSelectedPId = [];
      let totalAmount = 0;

      // Loop through all data to check each item and accumulate values
      data.forEach((item, index) => {
        const checkbox = document.getElementById(`chkbox${index}`);
        checkbox.checked = true;  // Check the checkbox
        // const checkboxpur = document.getElementById(`purchkbox${index}`);
        // var purch = checkboxpur.checked

        const address = item.address;
        const ref_address = item.ref_address;
        var pid = item.bot_name

        const token = parseFloat(item.t_rcv);  // Assuming 'amount' is the right field for your use case
        // const token = web3?.utils?.toWei(item.token.toString(), "ether");  // Assuming 'amount' is the right field for your use case

        allSelectedAddresses.push(address);
        allSelectedRefAddresses.push(ref_address);
        allSelectedPId.push(pid);
        allSelectedPurch.push(true);
        totalAmount += token;  // Accumulate total amount
      });

      // Update state with new selection
      setselected_address(allSelectedAddresses);
      setselected_address_referral(allSelectedRefAddresses);
      setselected_address_purchase(allSelectedPurch);
      setselected_pacakge_as(allSelectedPId);
      setValue(totalAmount);  // Update the total value

    } else {
      // Uncheck all checkboxes and reset state
      data.forEach((item, index) => {
        const checkbox = document.getElementById(`chkbox${index}`);
        checkbox.checked = false;  // Uncheck the checkbox
      });

      // Clear state and reset total amount
      setselected_address([]);
      setselected_address_purchase([]);
      setselected_address_referral([]);
      setselected_pacakge_as([]);
      setValue(0);  // Reset total amount
    }
  };
  const handleChange = (event, address, ref_address, name, amount, t_rcv, indtoremove) => {
    if (event?.target?.checked) {
      console.log("name", name);

      // var pid = 0
      // if (name === "Bot 1") {
      //   pid = 1
      // } else if (name === "Bot 2") {
      //   pid = 2
      // }
      // else if (name === "Bot 3") {
      //   pid = 3
      // }
      // else if (name === "Bot 4") {
      //   pid = 4
      // }

      setselected_address([...selected_address, address]);
      setselected_address_referral([...selected_address_referral, ref_address]);
      setselected_pacakge_as([...selected_pacakge_as, name]);
      // var amt = web3?.utils?.toWei(amount, "ether")
      // console.log("DA", t_rcv);

      // const amt = web3.utils.toWei(t_rcv, "mwei");  // Assuming 'amount' is the right field for your use case
      // console.log("DA", amt);
      // selected_address,
      //   selected_reff_address,
      //   selected_pacakge
      // const checkboxpur = document.getElementById(`purchkbox${indtoremove}`);
      // console.log("checkboxpur", checkboxpur)
      // var purch = checkboxpur === null ? false : checkboxpur.checked
      setselected_address_purchase([...selected_address_purchase, true]);
      setValue((prevValue) => prevValue + t_rcv);

    } else {
      const index = selected_address.findIndex(item => item === address);
      if (index !== -1) {
        setselected_address(selected_address.filter((_, ind) => ind !== index));
        setselected_address_purchase(selected_address_purchase.splice(index, 1));
        setselected_address_referral(selected_address_referral.filter((_, ind) => ind !== index));
        setselected_pacakge_as(selected_pacakge_as.filter((_, ind) => ind !== index));
        setValue((prevValue) => prevValue - t_rcv);
      }
    }
  };

  const AddAddressMultiple = async () => {
    const arbitrumRpcUrl = "https://arb1.arbitrum.io/rpc"; // Arbitrum mainnet RPC URL
    const provider = new JsonRpcProvider(arbitrumRpcUrl);
    // var contract = new web3c.eth.Contract(JSON.parse(settings.trade_contract_abi), settings.trade_contract);
    const contract = new Contract(settings.new_trade_contract, JSON.parse(settings.new_trade_contract_abi), signer);
    console.log("selected_address, selected_address_referral, selected_pacakge_as, selected_address_purchase",selected_address, selected_address_referral, selected_pacakge_as, selected_address_purchase);
    // console.log("logsss", selected_address, selected_reff_address, typeof (selected_pacakge));

    // Validate the selected_pacakge to ensure it's a valid uint8
    // if (isNaN(selected_pacakge) || selected_pacakge < 0 || selected_pacakge > 255) {
    //     toastError("Invalid package ID. It should be a number between 0 and 255.");
    //     return;
    // }

    // Validate the addresses
    // if (!web3c.utils.isAddress(selected_address) || !web3c.utils.isAddress(selected_reff_address)) {
    //   toastError("Invalid Ethereum address.");
    //   return;
    // }
    // console.log("Number(selected_pacakge) === 4", Number(selected_pacakge) === 4)
    // if (Number(selected_pacakge) === 4) {
    //   setselected_pacakge_as(Number(selected_pacakge) + 1)
    //   console.log("selected_pacakge", selected_pacakge_as)
    // } else if (Number(selected_pacakge) === 3) {
    //   setselected_pacakge_as(Number(selected_pacakge) + 3)
    //   console.log("selected_pacakge", selected_pacakge_as)
    // }
    // else {
    //   setselected_pacakge_as(Number(selected_pacakge) - 1)
    //   console.log("selected_pacakge", selected_pacakge_as)
    // }


    try {
      // Execute the contract method
      const txData = await contract.populateTransaction.addUser(selected_address, selected_address_referral, selected_pacakge_as, selected_address_purchase);
      const gasEstimate = await signer.estimateGas(txData);
      const gasPrice = ethers.utils.parseUnits("0.04", "gwei"); // Adjust this value based on network conditions
    
      
      const tx = await contract.addUser(selected_address, selected_address_referral, selected_pacakge_as, selected_address_purchase, {
        gasPrice: gasPrice,
        gasLimit: gasEstimate, // Use the estimated gas limit
      });
      console.log("Transaction sent, waiting for confirmation...");

      // Wait for the transaction to be mined and confirmed
      let receipt = null;
      while (receipt === null) {
        receipt = await provider.getTransactionReceipt(tx.hash); // Fetch the receipt using the hash
        if (receipt === null) {
          console.log("Waiting for transaction to be mined...");
          await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds before checking again
        }
      }

      // await contract.methods.addUser(selected_address, selected_address_referral, selected_pacakge_as, selected_address_purchase).send({ from: account, gasPrice: 10000000 })
      //   .then(async res => {
      //     console.log("Ress", res);

      await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "admin",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        // hash: res.transactionHash, // You can use the actual transaction hash,
        hash: tx.hash, // You can use the actual transaction hash,
        address: selected_address,
        reff_address: selected_address_referral,
        pacakge: selected_pacakge_as,
      })
        .then((res) => {
          getData();
          setselected_address("");
          setselected_address_referral("");
          setselected_pacakge("");
          if (res.data.error) {
            return "";
          }
          toastSuccess("Transaction Done");
        });
      // });
    } catch (err) {
      console.error("Error in sending transaction:", err);
      toastError("Transaction failed.");
    }

    settrdone(true);
  };

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [pages, pageSize, currentPage, search]);

  useEffect(() => {
    console.log(selected_address);
    console.log(selected_address_purchase);
    console.log(selected_address_referral);
    console.log(selected_pacakge_as);
  }, [selected_address, selected_address_purchase])

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  Pending Users
                </li>
              </ol>
            </div>
            <div className="d-flex  pe-5">
              <div>
                <h5 class="py-3 px-5 text-break">🟢{account ? formatAddress(account) : "Not Connected"}</h5>
                {/* <h5 class="px-5">Total: {value} {process.env.REACT_APP_TICKER}</h5> */}
                <p class="px-5">
                  <input className="p-5" type="checkbox" name={`chkbox`} id={`chkbox`} onChange={(ed) => selectAll(ed)} /> Select All</p>
              </div>
              <div>
                {address ? (
                  <>
                    {trdone ? (
                      <button
                        className="btn btn-success m-1"
                        onClick={() => AddAddressMultiple()}
                      >
                        Give Withdrawal
                      </button>
                    ) : (
                      <img
                        src="/assets/images/loader.gif"
                        style={{ height: "80px" }}
                        alt="Loading"
                      />
                    )}
                  </>
                ) : (
                  <>
                    <ConnectButton />
                  </>
                )}
                {address}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="form-head d-flex align-items-center">
                      <div className="input-group search-area d-inline-flex m-3">
                        <input
                          type="text"
                          className="form-control form-control-rev"
                          placeholder="Search here"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text input-group-text-rev"
                            onClick={getData}
                          >
                            <i className="flaticon-381-search-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <h3 class="py-3 px-5">Pending Users</h3>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Purchase</th>
                            <th>Hash</th>
                            <th>Address</th>
                            <th>Reff. Address</th>
                            {/* <th>Reward Eligibility</th> */}
                            <th>Stake($)</th>
                            <th>Status</th>
                            {/* <th>CreatedAt</th> */}
                            {account ?
                              <th>Actions</th>
                              : ""}
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((e, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>
                                    {/* {ind} */}
                                    {ind} < input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.ref_address, e.bot_name, e.bot_pkg_amount, true)} />
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name={`purchkbox${i}`}
                                      id={`purchkbox${i}`}
                                      checked={true}
                                    // onChange={(e) => handleChange(e.target.checked)}
                                    />
                                  </td>
                                  <td className="text-primary">
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/tx/${e.hash}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.hash)}
                                    </a>
                                    {e.hash ? <Copy data={e.hash} /> : ""}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.address)} {" "}{e.bot_name}
                                    </a>
                                    {e.address ? (
                                      <Copy data={e.address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${e.ref_address}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.ref_address)}
                                    </a>
                                    {e.ref_address ? (
                                      <Copy data={e.ref_address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  {/* <td>{e.income_capability}X</td> */}
                                  <td>$ {e.amount}</td>
                                  <td>
                                    {e.status === 1 ? "Active" : "Over"}
                                  </td>
                                  <td>
                                    {account ?
                                      <>{trdone ?
                                        <button
                                          className="btn btn-success m-1" id={ind}
                                          onClick={() => AddAddressMultiple(e.address, e.ref_address, e.bot_name)}
                                        >
                                          Add to Arbiscan
                                        </button>
                                        : <img
                                          src="/assets/images/loader.gif"
                                          style={{ height: "80px" }}
                                          alt="icon"
                                        />
                                      }
                                      </> :
                                      ""}
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
