import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { GMCContext } from "../../GMCContext";
import { Link } from "react-router-dom";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";

export default function Members() {
  const { formatAddress } = useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [editAddress, setEditAddress] = useState("");
  const [blockomeModal, setBlockomeModal] = useState(false);
  const [blockomeErr, setBlockomeErr] = useState(false);
  const [block, setBlock] = useState(false);
  const [profits, setProfits] = useState(0);
  const [address, setAddress] = useState("");


  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "user",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
          search: search,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };

  const fetchProfit = async (address) => {
    // console.log("Fetching profit for address:", address);

    try {
      const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "profitbyid",
        address: address,
        key: process.env.REACT_APP_KEY,
      });

      // console.log("Backend response:", res.data);

      if (res.data.resCode !== 400) {
        // console.log("Profit data:", res.data);
        setProfits((prev) => ({ ...prev, [address]: res.data })); // Store profit by user address
      } else {
        console.error("Error in profit response:", res.data.error);
      }
    } catch (err) {
      console.error("Error fetching profit for address:", address, err);
    }
  };

  useEffect(() => {
    // console.log("Data for fetching profits:", data);

    if (data && data.length) {
      data.forEach((ele) => {
        if (ele.address) {
          fetchProfit(ele.address);
        } else {
          console.error("Address missing in data element:", ele);
        }
      });
    } else {
      console.warn("No data available to fetch profits.");
    }
  }, [data]);
  // console.log("data:", data)
  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
    fetchProfit(address);
  }, [address, pages, pageSize, currentPage, search]);

  const editBonus = (address = "", status = false) => {
    setEditAddress(address);
    setBlock(status);
    setBlockomeModal(true);
  };

  const blockomeModalClose = () => {
    setEditAddress("");
    setBlock(false);
    
    setBlockomeModal(false);
  };

  const saveblockome = async () => {
    try {
      if (editAddress) {
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "user",
            submethod: "block",
            status: block,
            address: editAddress,
            key: process.env.REACT_APP_KEY,
          })
          .then((res) => {
            if (res.data.error) {
              setBlockomeErr(res.data.error);
            } else {
              setBlockomeModal(false);
              setBlockomeErr("");
            }
            getData();
          });


      } else {
        setBlockomeErr("Address not found.");
      }
    } catch (err) {
      console.log("error : ", err);
      setBlockomeErr(err.message);
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">Users</li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="text"
                  className="form-control pe-0"
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="input-group-text "
                    onClick={() => setSearch("")}
                  >
                    {search ? (
                      <img
                        src="./assets/images/close.png"
                        alt=""
                        style={{ height: "30px" }}
                      />
                    ) : (
                      <i className="flaticon-381-search-2" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <div className="card-title text-light">Members</div>
                      <span
                        className="btn btn-primary btn-sm "
                        onClick={() => getData()}
                      >
                        <i
                          className="flaticon-381-repeat-1"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Block</th>
                            <th>User Address</th>
                            <th>Ref.Address</th>
                            <th>Total Stake</th>
                            <th>Left Leg</th>
                            <th>Right Leg</th>
                            <th>Left Leg Matching</th>
                            <th>Right Leg Matching</th>
                            <th>Direct Reward</th>
                            <th>Trading Profit</th>
                            <th>Matching Bonus</th>
                            <th>Performance Bonus</th>
                            <th>Date</th>
                            {/* <th>Balance</th> */}
                            {/* <th>Date</th>
                            <th>Status</th>
                             */}
                             <th>Report</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((ele, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>
                                    <span className="d-flex justify-content-center">
                                      {ind}
                                    </span>
                                  </td>
                                  <td>
                                    {ele.blocked ? (
                                      <span className="badge badge-success">Yes</span>) : (
                                        <span className="badge badge-danger">No</span>)}
                                        &nbsp;
                                    <button type="button" className="btn btn-primary btn-xs"  onClick={() => editBonus(ele.address, ele.blocked)}>
                                      Edit
                                    </button>
                                  </td>
                                  {/* <td>{ele.rank ? ele.rank :"-"}</td> */}
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${ele.address}`}
                                      rel="noreferrer"
                                    >{formatAddress(ele.address)}
                                    </a>
                                    {ele.address ? (
                                      <Copy data={ele.address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${ele.ref_address}`}
                                      rel="noreferrer"
                                    >

                                      {formatAddress(ele.ref_address)}

                                    </a>
                                    {ele.ref_address ? (
                                      <Copy data={ele.ref_address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <span className="d-flex justify-content-center">
                                      ${ele?.t_staking ? ele?.t_staking : "0"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex justify-content-center">
                                      $ {ele?.left_leg_business ? ele?.left_leg_business : "0"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex justify-content-center">
                                      $ {ele?.right_leg_business ? ele?.right_leg_business : "0"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="d-flex justify-content-center">
                                      {/* {ele.level_inc > 0 ? `$${ele.level_inc}` : "0"} */}
                                      ${ele?.left_leg_business_matching ? ele?.left_leg_business_matching : "0"}


                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      ${ele.right_leg_business_matching ? ele.right_leg_business_matching : "0"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      ${ele.direct_rew ? ele.direct_rew : "0"}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      $
                                      {profits[ele.address] !== undefined ? (profits[ele.address]).toFixed(2) : "0"}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {ele.status === true ? (
                                        <>
                                          <div className="green-dot-active mx-2"></div>
                                          Active
                                        </>
                                      ) : (
                                        <>
                                          <div className="red-dot-active mx-2"></div>
                                          Deactive
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-flex justify-content-center">
                                      ${ele?.performance_rew ? ele?.performance_rew : "0"}
                                    </span>
                                  </td>
                                  <td>
                                    {ele.createdAt}
                                  </td>
                                  <td>
                                    <span className="me-3">
                                      <Link
                                        className="edit-appointment"
                                        type="button"
                                        to={`/userreport/${ele.address}`}
                                      >
                                        <i
                                          className="fa fa-book fs-18 "
                                          aria-hidden="true"
                                        />
                                      </Link>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>


      <div
        className={`modal fade ${blockomeModal ? "show" : ""}`}
        id="confirmUpd"
        style={blockomeModal ? { display: "block" } : { display: "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Are you sure??</h5>
            </div>
            <div className="modal-body">
              {blockomeErr ? (
                <>
                  <small id="emailHelp" class="form-text text-danger">
                    {blockomeErr}
                  </small>
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
              
              <div class="form-check">
                <input
                  type="text"
                  className="form-control"
                  id="exampleCheck2"
                  value={editAddress}
                />
                <label class="form-check-label" for="exampleCheck2">
                  Address
                </label>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  checked={block}
                  onChange={(e) => setBlock(e.target.checked)}
                />
                <label class="form-check-label" for="exampleCheck1">
                  Block
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light px-3 py-2"
                data-bs-dismiss="modal"
                onClick={blockomeModalClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary px-3 py-2"
                data-bs-dismiss="modal"
                onClick={() => saveblockome()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
