import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { GMCContext } from "../../GMCContext";
import { FaUsers } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { PiCoinsLight } from "react-icons/pi";
import { GiRank3 } from "react-icons/gi";
import { MdOutlinePendingActions } from "react-icons/md";

export default function Sidebar() {
  const { setToggleAchieveRank, toggleAchieveRank } = useContext(GMCContext);
  const epoint = useLocation().pathname;

  // Clear the toggle when switching to other menu items
  const clearToggle = () => {
    setToggleAchieveRank("");
  };

  // Handle click to show all staking options
  const handleStakingClick = () => {
    setToggleAchieveRank(toggleAchieveRank === "achieve-rank" ? "" : "achieve-rank");
  };

  return (
    <div className="deznav">
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {/* Dashboard */}
          <li
            className={`${epoint === "/dashboard" ? "mm-active active-no-child" : ""
              }`}
            onClick={clearToggle}
          >
            <Link className="ai-icon" to="/dashboard" aria-expanded="false">
              <RxDashboard color="white" size="1.2rem" />
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>

          {/* Users */}
          <li
            className={`${epoint === "/users" || epoint.includes("/userreport")
                ? "mm-active active-no-child"
                : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/users" className="ai-icon" aria-expanded="false">
              <FaUsers color="white" size="1.2rem" />
              <span className="nav-text">Users</span>
            </Link>
          </li>
          <li
            className={`${epoint === "/pending-users" || epoint.includes("/userreport")
                ? "mm-active active-no-child"
                : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/pending-users" className="ai-icon" aria-expanded="false">
              <FaUsers color="white" size="1.2rem" />
              <span className="nav-text">Pending Users</span>
            </Link>
          </li>
          {/* Staking with Sub-menu */}
          <li
            className={
              toggleAchieveRank === "achieve-rank" ? "mm-active" : ""
            }
          >
            <Link
              className="has-arrow ai-icon"
              onClick={() =>
                setToggleAchieveRank(
                  toggleAchieveRank === "achieve-rank" ? "" : "achieve-rank"
                )
              }
            >
              <FaRankingStar color="white" size="1.2rem" />
              <span className="nav-text"> Staking</span>
            </Link>
            <ul
              style={
                toggleAchieveRank === "achieve-rank"
                  ? {}
                  : { height: "16px", display: "none" }
              }
            >
              <li
                className={`${epoint === "/fifty" ? "mm-active" : ""
                  }`}
              >
                <Link to="/fifty" className="ai-icon">
                  <span className="nav-text">50</span>
                </Link>
              </li>
              <li
                className={`${epoint === "/hundred" ? "mm-active" : ""
                  }`}
              >
                <Link to="/hundred" className="ai-icon">
                  <span className="nav-text">100</span>
                </Link>
              </li>
              <li
                className={`${epoint === "/twohundredfifty" ? "mm-active" : ""
                  }`}
              >
                <Link to="/twohundredfifty" className="ai-icon">
                  <span className="nav-text">200</span>
                </Link>
              </li>
              <li
                className={`${epoint === "/fivehundred" ? "mm-active active-no-child" : ""
                  }`}
              >
                <Link to="/fivehundred" className="ai-icon">
                  <span className="nav-text">500</span>
                </Link>
              </li>
            </ul>
          </li>


          {/* Team */}
          <li
            className={`${epoint === "/dailyreward" ? "mm-active active-no-child" : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/dailyreward" className="ai-icon" aria-expanded="false">
              <FaRankingStar color="white" size="1.2rem" />
              <span className="nav-text">Team</span>
            </Link>
          </li>

          {/* Rank Reward */}
          <li
            className={`${epoint === "/directreward" ? "mm-active active-no-child" : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/directreward" className="ai-icon" aria-expanded="false">
              <MdOutlinePendingActions color="white" size="1.2rem" />
              <span className="nav-text">Rank Reward</span>
            </Link>
          </li>
          <li
            className={`${epoint === "/SponserRewardSidbar" ? "mm-active active-no-child" : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/SponserRewardSidbar" className="ai-icon" aria-expanded="false">
              <MdOutlinePendingActions color="white" size="1.2rem" />
              <span className="nav-text">Sponser Reward</span>
            </Link>
          </li>

          {/* Withdrawal */}
          <li
            className={`${epoint === "/MatchingRewardSidbar" ? "mm-active active-no-child" : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/MatchingRewardSidbar" className="ai-icon" aria-expanded="false">
              <GiRank3 color="white" size="1.2rem" />
              <span className="nav-text">Matching  Reward</span>

            </Link>
          </li>
          <li
            className={`${epoint === "/Pwithdrawal" ? "mm-active active-no-child" : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/Pwithdrawal" className="ai-icon" aria-expanded="false">
              <GiRank3 color="white" size="1.2rem" />
              <span className="nav-text">Pending Withdrawal</span>
            </Link>
          </li>
          <li
            className={`${epoint === "/withdrawal" ? "mm-active active-no-child" : ""
              }`}
            onClick={clearToggle}
          >
            <Link to="/withdrawal" className="ai-icon" aria-expanded="false">
              <GiRank3 color="white" size="1.2rem" />
              <span className="nav-text">Withdrawal</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
