import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Index from "./Components/Home/Index";
import Members from "./Components/Members/Members";
import MemberReport from "./Components/Members/MemberReport";
import Login from "./Components/Login";
// import LevelIncome from "./Components/Report/LevelIncome";
// import CommunityIncome from "./Components/Report/CommunityIncome";
// import RoiOfRoi from "./Components/Report/RoiOfRoi";
import Stakings from "./Components/Report/Stakings";
// import Unstake from "./Components/Report/Unstake";
import PendingWithdrawal from "./Components/Report/PendingWithdrawal";
import BlockA from "./Components/Block/BlockA";
import BlockB from "./Components/Block/BlockB";
import Cron from "./Components/Cron";
// import RDList from "./Components/Report/RDList";
import RetirementReward from "./Components/Report/RetirementReward";
// import Agency from "./Components/Report/Agency";
import Stake from "./Components/Stake/Stake";
import LevelRewardSidebar from "./Components/Sidbar/LevelRewardSidebar";
import SalaryRewardSidbar from "./Components/Sidbar/SalaryRewardSidbar";
import Rewardsidbar from "./Components/Sidbar/Rewardsidbar";
// import BonanzaSidbar from "./Components/Sidbar/BonanzaSidbar";
import QrCodeAuth from "./Components/QrCodeAuth";
import ProtecteRoutes from "./Components/ProtecteRoutes";
import StakingSidbar from "./Components/Sidbar/StakingSidbar";
import TeamSidbar from "./Components/Sidbar/TeamSidbar";
import RankRewardSidbar from "./Components/Sidbar/RankRewardSidbar";
import WithdrawalSidbar from "./Components/Sidbar/WithdrawalSidbar";
import Fifty from "./Components/StakingType/Fifty";
import FiveHundred from "./Components/StakingType/FiveHundred";
import Hundred from "./Components/StakingType/Hundred";
import TwoHundredFifty from "./Components/StakingType/TwoHUndredFifty";
import MatchingRewardSidbar from "./Components/Sidbar/MatchingRewardSidbar";
import SponserRewardSidbar from "./Components/Sidbar/SponserRewardSidbar";
import PendingWithdrawalSidbar from "./Components/Sidbar/PendingWithdrawalSidbar";
import PendingMembers from "./Components/Members/PendingMembers";
import AddStaking from "./Components/Sidbar/AddStaking";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/" element={<Login />} />
          <Route path="/authentication" element={<QrCodeAuth />} />

          <Route element={<ProtecteRoutes />}>
            <Route path="/dashboard" element={<Index />} />
            <Route path="/users" element={<Members />} />
            <Route path="/pending-users" element={<PendingMembers />} />
            <Route path="/userreport/:address" element={<MemberReport />} />
            {/* <Route path="/aggregator-reward" element={<LevelIncome />} /> */}
            {/* <Route path="/mining-reward" element={<CommunityIncome />} /> */}
            {/* <Route path="/cold-reward" element={<RoiOfRoi />} /> */}
            <Route path="/stakings" element={<Stakings />} />
            {/* <Route path="/unstake" element={<Unstake />} /> */}
            <Route path="/withdrawal" element={<WithdrawalSidbar />} />
            <Route path="/Pwithdrawal" element={<PendingWithdrawalSidbar />} />
            <Route path="/pending-withdrawal" element={<PendingWithdrawal />} />
            <Route path="/stake" element={<Stake />} />
            {/* <Route path="/rd-list" element={<RDList />} /> */}
            {/* <Route path="/agency" element={<Agency />} /> */}
            <Route path="/retirement-reward" element={<RetirementReward />} />
            <Route path="/block-a" element={<BlockA />} />
            <Route path="/block-b" element={<BlockB />} />
            <Route path="/crons" element={<Cron />} />
            <Route path="/dailyreward" element={<TeamSidbar />} />
            <Route path="/levelreward" element={<LevelRewardSidebar />} />
            <Route path="/investment" element={<StakingSidbar />} />
            <Route path="/fifty" element={<Fifty />} />
            <Route path="/fivehundred" element={<FiveHundred />} />
            <Route path="/hundred" element={<Hundred />} />
            <Route path="/twohundredfifty" element={<TwoHundredFifty />} />
            <Route path="/directreward" element={<RankRewardSidbar />} />
            <Route path="/salaryreward" element={<SalaryRewardSidbar />} />
            <Route path="/reward" element={<Rewardsidbar />} />
            <Route path="/MatchingRewardSidbar" element={<MatchingRewardSidbar />} />
            <Route path="/SponserRewardSidbar" element={<SponserRewardSidbar />} />
            <Route path="/addtsx" element={<AddStaking />} />

            
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
