import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";
import Web3 from "web3";
import useEthersSigner from './useEthersSigner'; // Import the hook for getting signer
import { Contract, ethers } from "ethers"; // Import ethers
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useConnect, useDisconnect } from 'wagmi';

export default function PendingWithdrawalSidbar() {
  const { formatAddress, settings, web3, contract, formatDateTime, toastSuccess } = useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const [editAddress, setEditAddress] = useState("");
  const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
  const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
  const [bonusInc, setBonusInc] = useState(false);
  const [bonusPer, setBonusPer] = useState(0);
  const [bonusAmt, setBonusAmt] = useState(0);
  const [userwithdrawl, setuserwithdrawl] = useState(false);
  // console.log("2faAuth", localStorage.getItem("2faAuth"));

  const [account, setAccount] = useState(null);
  const [web3c, setWeb3c] = useState(null);
  const [value, setValue] = useState(0);
  const [trdone, settrdone] = useState(true);
  const [selected_address, setselected_address] = useState([]);
  const [selected_address_amt, setselected_address_amt] = useState([]);
  const [selected_address_amt_as, setselected_address_amt_as] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authNumber, setAuthNumber] = useState('');
  const [msg, setMsg] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const signer = useEthersSigner(); // Call the hook here

  const { isConnected, address } = useAccount();
  console.log("address", address);

  const insertCode = async () => {
    try {
      const state = JSON.parse(localStorage.getItem('mark#0143'));
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "verify",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        authNumber,
        username: state.username,
        _id: state._id,
      });

      const { resCode, error, token } = response.data;

      if (resCode === 200 && token) {
        setMsg("Verified successfully");
        setShowMsg(true);
        setIsAuthenticated(true);
      } else {
        setErrorMessage(error || "Verification failed. Please try again.");
        setError(true);
      }
    } catch (err) {
      console.error("Error inserting data:", err);
      setErrorMessage("Network error. Please try again later.");
      setError(true);
    }
  };

  const connectMetaMask = async () => {
    if (window.ethereum) {
      // MetaMask exists
      try {
        // Request account access if needed
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3Instance = new Web3(window.ethereum);

        // Set the web3 instance and the user's account
        setWeb3c(web3Instance);
        setAccount(accounts[0]);

        console.log("Connected account:", accounts[0]);
      } catch (error) {
        console.error("User denied account access or error occurred:", error);
      }
    } else {
      // MetaMask is not installed
      alert("Please install MetaMask to use this app.");
    }
  };
  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "withdrawal",
          submethod: "getpw",
          key: process.env.REACT_APP_KEY,
          search: search,
          pageSize: pageSize,
          page: currentPage,
        })
        .then((res) => {
          console.log("data", res.data.data);
          setdata(res.data.data);
          setPages(res.data.pages ? res.data.pages : 1);
          setLoading(false);
          if (res.data.error) {
            return "";
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
      setPages(1);
    }
  };
  console.log('data', data)
  const selectAll = (event) => {
    if (event?.target?.checked) {
      // Reset arrays and total amount to start fresh
      let allSelectedAddresses = [];
      let allSelectedAmountsWei = [];
      let allSelectedAmounts = [];
      let totalAmount = 0;

      // Loop through all data to check each item and accumulate values
      data.forEach((item, index) => {
        const checkbox = document.getElementById(`chkbox${index}`);
        checkbox.checked = true;  // Check the checkbox

        const address = item.address;
        const amount = parseFloat(item.amount);  // Assuming 'amount' is the right field for your use case
        const token = parseFloat(item.t_rcv);  // Assuming 'amount' is the right field for your use case
        // const token = web3?.utils?.toWei(item.token.toString(), "ether");  // Assuming 'amount' is the right field for your use case
        const amountWei = web3?.utils?.toWei(item.t_rcv, "mwei");

        allSelectedAddresses.push(address);
        allSelectedAmountsWei.push(amountWei);
        allSelectedAmounts.push(amount);
        totalAmount += token;  // Accumulate total amount
      });

      // Update state with new selection
      setselected_address(allSelectedAddresses);
      setselected_address_amt(allSelectedAmountsWei);
      setselected_address_amt_as(allSelectedAmounts);
      setValue(totalAmount);  // Update the total value

    } else {
      // Uncheck all checkboxes and reset state
      data.forEach((item, index) => {
        const checkbox = document.getElementById(`chkbox${index}`);
        checkbox.checked = false;  // Uncheck the checkbox
      });

      // Clear state and reset total amount
      setselected_address([]);
      setselected_address_amt([]);
      setselected_address_amt_as([]);
      setValue(0);  // Reset total amount
    }
  };

  // const withdrawMultiple = async (res) => {
  //   // console.log("settings", settings)
  //   // console.log("account", account)
  //   settrdone(false)
  //   var contract = new web3c.eth.Contract(JSON.parse(settings.trade_contract_abi), settings.trade_contract)

  //   // 1 - withdrawalProtocol
  //   // 0 - withdrawal
  //   // await contract.methods.withdraw(selected_address, selected_address_amt, web3.utils.toWei(value, "ether")).send({ from: account, gasPrice: '100000000000'  }, 
  //   console.log("1--------",selected_address);
  //   console.log("1--------",selected_address);

  //   await contract.methods.withdraw(selected_address, selected_address_amt).send({ from: account, gasPrice: '10000000' },
  //     async function (err, transactionId) {
  //       // console.log("tra", transactionId);
  //       if (err) {
  //         settrdone(true)
  //       }
  //     }).then(async res => {
  //       console.log("Ress", res);

  //       await axios
  //         .post(process.env.REACT_APP_BACKEND_LINK, {
  //           method: "withdrawal",
  //           submethod: "getpwmultiple",
  //           key: process.env.REACT_APP_KEY,
  //           hash: "res.transactionHash-1",
  //           address: selected_address,
  //           amount: selected_address_amt,
  //           total: value,
  //           // type: 0,
  //         })
  //         .then((res) => {
  //           for (let i = 0; i < selected_address.length; i++) {
  //             const checkbox = document.getElementById(`chkbox${i}`);
  //             if (checkbox) {
  //               checkbox.checked = false;
  //             }

  //           }
  //           getData()
  //           setselected_address([])
  //           setselected_address_amt([])
  //           setValue(0)
  //           if (res.data.error) {
  //             return "";
  //           }
  //           toastSuccess("Transaction Done")

  //         });
  //     });
  //   settrdone(true);
  // }
  // const withdrawMultiple = async () => {
  //   if (!signer) {
  //     console.log("Signer is not available");
  //     return;
  //   }

  //   settrdone(false);

  //   try {
  //     // Create contract instance using ethers.js
  //     const contract = new Contract(settings.trade_contract, JSON.parse(settings.trade_contract_abi), signer);

  //     // Call the withdraw method of the contract
  //     const tx = await contract.withdraw(selected_address, selected_address_amt, {
  //       gasPrice: ethers.utils.parseUnits("0.04", "gwei"),
  //     });

  //     // Wait for the transaction to be mined
  //     await tx.wait();

  //     console.log("Transaction successful:", tx);

  //     // Call backend API to register the withdrawal
  //     await axios
  //       .post(process.env.REACT_APP_BACKEND_LINK, {
  //         method: "withdrawal",
  //         submethod: "getpwmultiple",
  //         key: process.env.REACT_APP_KEY,
  //         hash: tx.transactionHash,
  //         address: selected_address,
  //         amount: selected_address_amt,
  //         total: value,
  //       })
  //       .then((response) => {
  //         for (let i = 0; i < selected_address.length; i++) {
  //           const checkbox = document.getElementById(`chkbox${i}`);
  //           if (checkbox) {
  //             checkbox.checked = false;
  //           }
  //         }

  //         getData();
  //         setselected_address([]);
  //         setselected_address_amt([]);
  //         setValue(0);

  //         if (response.data.error) {
  //           return "";
  //         }

  //         toastSuccess("Transaction Done");
  //       });
  //   } catch (err) {
  //     console.error("Error in withdrawMultiple:", err);
  //     settrdone(true);
  //   }

  //   settrdone(true);
  // };


  // const withdrawMultiple = async () => {
  //   if (!signer) {
  //     console.log("Signer is not available");
  //     return;
  //   }

  //   settrdone(false);

  //   try {
  //     // Create contract instance using ethers.js
  //     const contract = new Contract(settings.trade_contract, JSON.parse(settings.trade_contract_abi), signer);

  //     // Estimate gas before sending the transaction
  //     const txData = await contract.populateTransaction.withdraw(selected_address, selected_address_amt);

  //     // Estimate the gas required for the transaction
  //     const gasEstimate = await signer.estimateGas(txData);
  //     console.log("Estimated Gas: ", gasEstimate.toString());

  //     // Define the gas price for Arbitrum (adjust if necessary)
  //     const gasPrice = ethers.utils.parseUnits("0.04", "gwei"); // Adjust this value based on network conditions

  //     // Create the transaction object with estimated gas
  //     const tx = await contract.withdraw(selected_address, selected_address_amt, {
  //       // gasPrice: gasPrice,
  //       gasLimit: gasEstimate, // Use the estimated gas limit
  //     });

  //     // Wait for the transaction to be mined
  //     await tx.wait();

  //     console.log("Transaction successful:", tx);

  //     // Call backend API to register the withdrawal
  //     await axios
  //       .post(process.env.REACT_APP_BACKEND_LINK, {
  //         method: "withdrawal",
  //         submethod: "getpwmultiple",
  //         key: process.env.REACT_APP_KEY,
  //         hash: tx.transactionHash,
  //         address: selected_address,
  //         amount: selected_address_amt,
  //         total: value,
  //       })
  //       .then((response) => {
  //         for (let i = 0; i < selected_address.length; i++) {
  //           const checkbox = document.getElementById(`chkbox${i}`);
  //           if (checkbox) {
  //             checkbox.checked = false;
  //           }
  //         }

  //         getData();
  //         setselected_address([]);
  //         setselected_address_amt([]);
  //         setValue(0);

  //         if (response.data.error) {
  //           return "";
  //         }

  //         toastSuccess("Transaction Done");
  //       });
  //   } catch (err) {
  //     console.error("Error in withdrawMultiple:", err);
  //     settrdone(true);
  //   }

  //   settrdone(true);
  // };
  const { JsonRpcProvider } = ethers.providers; // Import JsonRpcProvider from the providers namespace
  
  const withdrawMultiple = async () => {
    if (!signer) {
      console.log("Signer is not available");
      return;
    }
  
    settrdone(false);
  
    // Define the Arbitrum provider (connecting to the Arbitrum network)
    const arbitrumRpcUrl = "https://arb1.arbitrum.io/rpc"; // Arbitrum mainnet RPC URL
    const provider = new JsonRpcProvider(arbitrumRpcUrl); // Create the provider using the new method
  
    try {
      // Create contract instance using ethers.js
      const contract = new Contract(settings.trade_contract, JSON.parse(settings.trade_contract_abi), signer);
  
      // Estimate gas before sending the transaction
      const txData = await contract.withdraw(selected_address, selected_address_amt);
  
      // Estimate the gas required for the transaction
      const gasEstimate = await signer.estimateGas(txData);
      console.log("Estimated Gas: ", gasEstimate.toString());
  
      // Define the gas price for Arbitrum (adjust if necessary)
      const gasPrice = ethers.utils.parseUnits("0.04", "gwei"); // Adjust this value based on network conditions
  
      // Create the transaction object with estimated gas
      const tx = await contract.withdraw(selected_address, selected_address_amt, {
        gasPrice: gasPrice,
        gasLimit: gasEstimate, // Use the estimated gas limit
      });
  
      console.log("Transaction sent, waiting for confirmation...");
  
      // Wait for the transaction to be mined and confirmed
      let receipt = null;
      while (receipt === null) {
        receipt = await provider.getTransactionReceipt(tx.hash); // Fetch the receipt using the hash
        if (receipt === null) {
          console.log("Waiting for transaction to be mined...");
          await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds before checking again
        }
      }
  
      // Once transaction is confirmed, log the details
      console.log("Transaction successful:", tx);
      console.log("Transaction mined in block:", receipt.blockNumber);
  
      // Call backend API to register the withdrawal
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "withdrawal",
          submethod: "getpwmultiple",
          key: process.env.REACT_APP_KEY,
          hash: tx.hash,
          address: selected_address,
          amount: selected_address_amt,
          total: value,
        })
        .then((response) => {
          // Uncheck checkboxes after the transaction
          for (let i = 0; i < selected_address.length; i++) {
            const checkbox = document.getElementById(`chkbox${i}`);
            if (checkbox) {
              checkbox.checked = false;
            }
          }
  
          getData();
          setselected_address([]);
          setselected_address_amt([]);
          setValue(0);
  
          if (response.data.error) {
            return "";
          }
  
          toastSuccess("Transaction Done");
        });
    } catch (err) {
      console.error("Error in withdrawMultiple:", err);
      settrdone(true);
    }
  
    settrdone(true);
  };
  
  const handleChange = (event, address, amount, t_rcv) => {
    if (event?.target?.checked) {
      setselected_address([...selected_address, address]);
      // var amt = web3?.utils?.toWei(amount, "ether")
      // console.log("DA", t_rcv);

      const amt = web3.utils.toWei(t_rcv, "mwei");  // Assuming 'amount' is the right field for your use case
      // console.log("DA", amt);

      setselected_address_amt([...selected_address_amt, amt]);
      setselected_address_amt_as([...selected_address_amt_as, amount]);
      setValue((prevValue) => prevValue + t_rcv);

    } else {
      const index = selected_address.findIndex(item => item === address);
      if (index !== -1) {
        setselected_address(selected_address.filter((_, ind) => ind !== index));
        setselected_address_amt(selected_address_amt.filter((_, ind) => ind !== index));
        setselected_address_amt_as(selected_address_amt_as.filter((_, ind) => ind !== index));
        setValue((prevValue) => prevValue - t_rcv);

      }
    }
  };
  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [pages, pageSize, currentPage, search]);

  useEffect(() => {
    // console.log(selected_address);
    // console.log(selected_address_amt);
    // console.log(selected_address_amt_as);
  }, [selected_address, selected_address_amt])
  // useEffect(() => {
  //   if (search) {
  //     setCurrentPage(1);
  //   }
  //   getData();
  // }, [pages, pageSize, currentPage, search]);

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            {/* Check if the user is authenticated */}
            {isAuthenticated ? (
              // Authentication Form
              <div
                className="auth-form-container d-flex justify-content-center align-items-center"
                style={{ height: '60vh' }}
              >
                <div className="auth-form" style={{ width: '60%' }}>
                  <div className="form-group">
                    <label className="form-label center">Authentication Number</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setAuthNumber(e.target.value)}
                    />
                  </div>

                  {/* Display Error Message */}
                  {error && errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}

                  {/* Display Success Message */}
                  {showMsg && msg && (
                    <div className="alert alert-success" role="alert">
                      {msg}
                    </div>
                  )}

                  {/* Login Button */}
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={insertCode}
                    >
                      Log In
                    </button>
                  </div>
                </div>
              </div>

            ) : (
              // Main Content when authenticated
              <>
                <div className="page-titles">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active text-primary">
                      Pending Withdrawal
                    </li>
                  </ol>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body p-0">
                        <div className="form-head d-flex align-items-center">
                          <div className="input-group search-area d-inline-flex m-3">
                            <input
                              type="text"
                              className="form-control form-control-rev pe-0"
                              placeholder="Search here"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="input-group-text input-group-text-rev"
                                onClick={() => setSearch("")}
                              >
                                {search ? (
                                  <img
                                    src="./assets/images/close.png"
                                    alt=""
                                    style={{ height: "30px" }}
                                  />
                                ) : (
                                  <i className="flaticon-381-search-2" />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <h3 className="py-3 px-5">Pending Withdrawal</h3>
                        <div className="d-flex pe-5">
                          <div>
                            <h5 className="py-3 px-5 text-break">
                              🟢{account ? formatAddress(account) : "Not Connected"}
                            </h5>
                            <h5 className="px-5">
                              Total: {value} {process.env.REACT_APP_TICKER_USDN}
                            </h5>
                            <p className="px-5">
                              <input
                                className="p-5"
                                type="checkbox"
                                name={`chkbox`}
                                id={`chkbox`}
                                onChange={(ed) => selectAll(ed)}
                              />{" "}
                              Select All
                            </p>
                          </div>
                          <div>
                            {address ? (
                              <>
                                {trdone ? (
                                  <button
                                    className="btn btn-success m-1"
                                    onClick={() => withdrawMultiple()}
                                  >
                                    Give Withdrawal
                                  </button>
                                ) : (
                                  <img
                                    src="/assets/images/loader.gif"
                                    style={{ height: "80px" }}
                                    alt="Loading"
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {/* <button
                                  className="btn btn-success m-1"
                                  onClick={() => connectMetaMask()}
                                >
                                  Connect Metamask
                                </button> */}
                                <ConnectButton />
                              </>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="table-responsive">
                          <table
                            id="example5"
                            className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Address</th>
                                <th>Amount</th>
                                <th>Token</th>
                                <th>Fee (10%)</th>
                                <th>Total receive</th>
                                <th>Rate</th>
                                <th>Datetime</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data && data.length > 0 ? (
                                data.map((e, i) => {
                                  const ind = currentPage * pageSize - pageSize + (i + 1);
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {ind}{" "}
                                        <input
                                          type="checkbox"
                                          name={`chkbox${i}`}
                                          id={`chkbox${i}`}
                                          onChange={(ed) =>
                                            handleChange(ed, e.address, e.amount, e.t_rcv)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <a
                                          target="_blank"
                                          className="text-primary"
                                          href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                          rel="noreferrer"
                                        >
                                          {formatAddress(e.address)}
                                        </a>
                                        {e.address && <Copy data={e.address} />}
                                      </td>
                                      <td>$ {e.amount || "0"}</td>
                                      <td>
                                        {e.token || "0"} {e.token_ticker}
                                      </td>
                                      <td>
                                        {e.fee || "0"} {e.token_ticker}
                                      </td>
                                      <td>
                                        {e.t_rcv || "0"} {e.token_ticker}
                                      </td>
                                      <td>{e.rate || "0"}</td>
                                      <td>{e.datetime || "-"}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="8" className="text-center">
                                    No data found.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {loading && (
                            <div className="text-center">
                              <p>Loading...</p>
                            </div>
                          )}
                        </div>
                        <Pagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          pages={pages}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );

}
